import React from 'react';
import ReactDOM from 'react-dom';

import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';

import App from './App';
import AppStateProvider, { useAppState } from './state';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import ErrorDialog from './components/ErrorDialog/ErrorDialog';
import LoginPage from './components/LoginPage/LoginPage';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import theme from './theme';
import './types';
import { VideoProvider } from './components/VideoProvider';
import useConnectionOptions from './utils/useConnectionOptions/useConnectionOptions';
import UnsupportedBrowserWarning from './components/UnsupportedBrowserWarning/UnsupportedBrowserWarning';
import ConcentPage from './components/VideoSupportPage/ConcentPage';
import FeedBack from './components/VideoSupportPage/FeedBack';
import MedicineConsent from './components/VideoSupportPage/MedicineConsent';

const VideoApp = () => {
  const { error, setError } = useAppState();
  const connectionOptions = useConnectionOptions();

  return (
    <UnsupportedBrowserWarning>
      <VideoProvider options={connectionOptions} onError={setError}>
        <ErrorDialog dismissError={() => setError(null)} error={error} />
        <App />
      </VideoProvider>
    </UnsupportedBrowserWarning>
  );
};

const SupportPage = () => {
  if(type != null  && conversationId != null && type== 'm'){
    window.history.replaceState(null, '', window.encodeURI(`/${type}/${conversationId}${window.location.search || ''}`));
  return ( 
  <MedicineConsent/>
    );
  }else if (type != null && conversationId != null) {
    window.history.replaceState(null, '', window.encodeURI(`/${type}/${conversationId}${window.location.search || ''}`));
    
    return (
      <ConcentPage />
    );
  } else {
    return(
      <div style={{paddingRight: "10%" , paddingTop:"5%", paddingLeft:"10%" }}>
       <h1 style={{ color: "white", fontSize: "350%", fontFamily: "Serif", margin: "1%" }}>Your video consultation link seems to be broken, please ask your doctor to share the link via WhatsApp.</h1>
     </div>
      );
  }
}

var URL = window.location.href;
var type: string | null = null;
var conversationId: string | null = null;
if (URL.indexOf("/p/") !== -1) {
  type = URL.substring(URL.lastIndexOf("/p/") + 1, URL.lastIndexOf("/p/") + 2);
  if (URL.indexOf("conv_") !== -1) {
    conversationId = URL.substring(URL.lastIndexOf("conv_"), URL.lastIndexOf("conv_") + 15);
  }
} else if (URL.indexOf("/d/") !== -1) {
  type = URL.substring(URL.lastIndexOf("/d/") + 1, URL.lastIndexOf("/d/") + 2);
  if (URL.indexOf("conv_") !== -1) {
    conversationId = URL.substring(URL.lastIndexOf("conv_"), URL.lastIndexOf("conv_") + 15);
  }
}else if (URL.indexOf("/m/") !== -1) {
  type = URL.substring(URL.lastIndexOf("/m/") + 1, URL.lastIndexOf("/m/") + 2);
  conversationId = URL.substring(URL.lastIndexOf("/m/")+3, URL.length);
console.log(conversationId);

}



ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <Router>
      <AppStateProvider>
        <Switch>
          <PrivateRoute exact path="/">
            <SupportPage />
          </PrivateRoute>
          <PrivateRoute exact path="/FeedBack">
            <FeedBack />
          </PrivateRoute>
          <PrivateRoute path="/room/:URLRoomName">
            <VideoApp />
          </PrivateRoute>
          <Route path="/login">
            <LoginPage />
          </Route>
          <Redirect to="/" />
        </Switch>
      </AppStateProvider>
    </Router>
  </MuiThemeProvider>,
  document.getElementById('root')
);
