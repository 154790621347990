import React, { PropsWithChildren ,Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import enhanceMessage from './enhanceMessage';
import { TwilioError } from 'twilio-video';
import Strings from '../../Strings.js';
import PopupModal from '../VideoSupportPage/modelDemo';

interface ErrorDialogProps {
  dismissError: Function;
  error: TwilioError | null;
}
const ModalDialog = () => {
  new PopupModal().render();
}
function ErrorDialog({ dismissError, error }: PropsWithChildren<ErrorDialogProps>) {
  const { message, code } = error || {};
  const enhancedMessage = enhanceMessage(message, code);
 
  if(code === 53118){
      var URL = window.location.href;
      var type: string | null = null;
      var conversationId: string | null = null;
  
      if (URL.indexOf("/p/") !== -1) {
        type = URL.substring(URL.lastIndexOf("/p/") + 1, URL.lastIndexOf("/p/") + 2);
  
      } else if (URL.indexOf("/d/") !== -1) {
        type = URL.substring(URL.lastIndexOf("/d/") + 1, URL.lastIndexOf("/d/") + 2);
  
      }
      conversationId = URL.substring(URL.lastIndexOf("conv_") + 5, URL.lastIndexOf("conv_") + 15);
      var name = URL.substring(URL.lastIndexOf("conv_") + 16 ,URL.length )
      console.log("MenuBar   "+conversationId);
      var conv = "conv_"+ conversationId;
     window.history.replaceState(null, '', window.encodeURI(`/${type}/${conv}/${name}${window.location.search || ''}`));
      localStorage.setItem('isDoctorEndCall' , '1');
    return (

      <PopupModal />
    );
  }else if(code === 53205){

    return (

      <Dialog open={error !== null} onClose={() => dismissError()} fullWidth={true} maxWidth="xs">
        <DialogTitle>{Strings.title.duplicateParticipant.errorTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{Strings.title.duplicateParticipant.contextText}</DialogContentText>
          {code && (
            <pre>
              <code>{Strings.title.duplicateParticipant.message}</code>
            </pre>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => ModalDialog()} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );

  }else if(code === 53106){

    return (

      <Dialog open={error !== null} onClose={() => dismissError()} fullWidth={true} maxWidth="xs">
        <DialogTitle>{Strings.title.roomNotFound.errorTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{Strings.title.roomNotFound.contextText}</DialogContentText>
          {code && (
            <pre>
              <code>{Strings.title.roomNotFound.message}</code>
            </pre>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => dismissError()} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );

  }else {
  return (
    <Dialog open={error !== null} onClose={() => dismissError()} fullWidth={true} maxWidth="xs">
      <DialogTitle>ERROR</DialogTitle>
      <DialogContent>
        <DialogContentText>{enhancedMessage}</DialogContentText>
        {code && (
          <pre>
            <code>Error Code: {code}</code>
          </pre>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => dismissError()} color="primary" autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
}

export default ErrorDialog;
