import React, { useState } from "react";
import Button from "@material-ui/core/Button";
export default function StartCallButton(props) {
  const [disabling, setDisabling] = useState(false);

  const { disabled, onClick, ...otherProps } = props;

  const onRealClick = async event => {
    setDisabling(true);
    await onClick();
  };

  return (
    <Button
      onClick={onRealClick}
      disabled={disabling || disabled}
      {...otherProps}
    />
  );
}
