import { Link } from "@material-ui/core";
import React, { useRef, useState } from 'react';

export default function CopyToClipboard() {
    var URL = window.location.href;
    URL = URL.substring(0, URL.lastIndexOf("/"));

    const copyToClipBoard = async (copyMe: string) => {
        try {
            await navigator.clipboard.writeText(copyMe);
            alert("Link Copied!");
        } catch (err) {
            alert("Failed to Copy!" + err);
        }
    };

    return (
        <div>
            Please
            {
                /* Logical shortcut for only displaying the 
                   button if the copy command exists */
                document.queryCommandSupported('copy') &&
                <div>
                    <button onClick={() => copyToClipBoard(URL)}>Copy</button>
                </div>
            }
            <Link
                target="_blank"
                rel="noopener"
            >
                {URL}
                </Link>
        {" "} open in one of the browsers mentioned below:
            <br />
            <ol>
                <li>Android: Chrome, Firefox</li>
                <li>iOS : Safari, Chrome</li>
            </ol>
            <br />
        </div>
    );
}