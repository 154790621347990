import React, { useCallback, useEffect, useState } from 'react';
import { DEFAULT_VIDEO_CONSTRAINTS } from '../../../constants';
import FlipCameraIosIcon from '@material-ui/icons/FlipCameraIos';
import { createStyles, Fab, IconButton, makeStyles, Theme, Tooltip } from '@material-ui/core';
import { LocalVideoTrack } from 'twilio-video';
import useMediaStreamTrack from '../../../hooks/useMediaStreamTrack/useMediaStreamTrack';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

export default function FlipCameraButton() {
  const {
    room: { localParticipant },
    getLocalVideoTrack,
  } = useVideoContext();
  const { localTracks } = useVideoContext();
  const [supportsFacingMode, setSupportsFacingMode] = useState<Boolean | null>(null);
  const videoTrack = localTracks.find(track => track.name.includes('camera')) as LocalVideoTrack;
  const mediaStreamTrack = useMediaStreamTrack(videoTrack);
  const facingMode = mediaStreamTrack?.getSettings().facingMode;

  useEffect(() => {
    // The 'supportsFacingMode' variable determines if this component is rendered
    // If 'facingMode' exists, we will set supportsFacingMode to true.
    // However, if facingMode is ever undefined again (when the user unpublishes video), we
    // won't set 'supportsFacingMode' to false. This prevents the icon from briefly
    // disappearing when the user switches their front/rear camera.
    const currentFacingMode = mediaStreamTrack?.getSettings().facingMode;
    if (currentFacingMode && supportsFacingMode === null) {
      setSupportsFacingMode(true);
    }
  }, [mediaStreamTrack, supportsFacingMode]);

  /* const toggleFacingMode = useCallback(() => {
    const newFacingMode = mediaStreamTrack?.getSettings().facingMode === 'user' ? 'environment' : 'user';

    videoTrack.restart({
      ...(DEFAULT_VIDEO_CONSTRAINTS as {}),
      facingMode: newFacingMode,
    });
  }, [mediaStreamTrack, videoTrack]); */
  const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      margin: theme.spacing(1),
      width:'35px',
      height:'30px',
    },
  })

);
  
const classes = useStyles();
const toggleFacingMode = useCallback(() => {
    const newFacingMode = mediaStreamTrack?.getSettings().facingMode === 'user' ? 'environment' : 'user';
    
    videoTrack!.stop();

    getLocalVideoTrack({ facingMode: newFacingMode }).then(newVideoTrack => {
      const localTrackPublication = localParticipant?.unpublishTrack(videoTrack!);
      // TODO: remove when SDK implements this event. See: https://issues.corp.twilio.com/browse/JSDK-2592
      localParticipant?.emit('trackUnpublished', localTrackPublication);

      localParticipant?.publishTrack(newVideoTrack, { priority: 'low' });
    });
  }, [facingMode, getLocalVideoTrack, localParticipant, videoTrack]);

  return supportsFacingMode ? (
    <Tooltip
    title={ 'Flip Camera'}
    placement="top"
    PopperProps={{ disablePortal: true }}
  >
    <Fab className={classes.fab} onClick={toggleFacingMode} disabled={!videoTrack}>
    <FlipCameraIosIcon style={{fontSize:"200%"}} />
    </Fab>
  </Tooltip>
  ) : null;
  
}
