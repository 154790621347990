import React from 'react';
import { styled } from '@material-ui/core/styles';
import '../../css/main.css';
import Strings from '../../Strings';
import UnsupportedBrowserWarning from '../UnsupportedBrowserWarning/UnsupportedBrowserWarning';
import { VideoProvider } from '../VideoProvider';
import ErrorDialog from '../ErrorDialog/ErrorDialog';
import App from '../../App';
import { useAppState } from '../../state';
import useConnectionOptions from '../../utils/useConnectionOptions/useConnectionOptions';
import Upload from './Upload';
import Feedback from './FeedBack';
import VideoSupportPage from './VideoSupportPage';

const axios = require('axios');
var URL = window.location.href;
var conversationId = null;
conversationId = URL.substring(URL.lastIndexOf("conv_") + 5, URL.lastIndexOf("conv_") + 15);
var type = null;

if (URL.indexOf("/d/") !== -1) {
    type = URL.substring(URL.lastIndexOf("/d/") + 1, URL.lastIndexOf("/d/") + 2);

} else if (URL.indexOf("/p/") !== -1) {
    type = URL.substring(URL.lastIndexOf("/p/") + 1, URL.lastIndexOf("/p/") + 2);

}

const VideoApp = () => {
    const { error, setError } = useAppState();
    const connectionOptions = useConnectionOptions();

    return (
        <UnsupportedBrowserWarning>
            <VideoProvider options={connectionOptions} onError={setError}>
                <ErrorDialog dismissError={() => setError(null)} error={error} />
                <App />
            </VideoProvider>
        </UnsupportedBrowserWarning>
    );
};

const Container = styled('div')({
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
});

const Main = styled('main')({
    overflow: 'hidden',
});

var patientname = null;
var doctorName = null;
export default class ConcentPage extends React.Component {
    constructor() {
        super();
        this.requesting = false;
        this.state = {
            permissionPage: false,
            isDisabled: true,
            name: null,
            consentList: null,
            consentVersion: null,
            entityId: null,
            doctorId: null,
            patientId: null,
            type: null,
            patient_name: null,
            doctor_name: null,
            patient_age: null,
            patient_phone: null,
            patient_sex: null,
            check: "off",
            ErrorPage : false
        }
        localStorage.setItem("callEndForPatient", "false");

        if(!this.requesting){
            this.requesting = true;
            console.log("Consent.js         ======>     "+Strings.title.urls.baseUrl + '/videoConsultation/getVideoConsultation/' + conversationId);
            
        axios.get(Strings.title.urls.baseUrl + '/videoConsultation/getVideoConsultation/' + conversationId)
            .then((response) => {
                console.log(response.data.result);
                if(response.data.result === null)
                {
                    this.setState({
                        ErrorPage : true
                    })
                }
                let mobNo = response.data.result.patient_details.phoneNo.toString();
                mobNo = mobNo.replace(/\s/g, '').replace(/\+91/gi, '')
                this.setState({
                    patient_name: response.data.result.video_consultation.name,
                    patientId: response.data.result.video_consultation.patient_id,
                    entityId: response.data.result.video_consultation.entity_id,
                    doctorId: response.data.result.doctor_details.doctor_id,
                    doctor_name: response.data.result.doctor_details.doctor_name,
                    patient_age: response.data.result.patient_age,
                    patient_sex: response.data.result.patient_details.gender,
                    patient_phone: mobNo,
                })
                patientname = this.state.patient_name;
                doctorName = this.state.doctor_name;
                localStorage.setItem('doctorName', doctorName);
                localStorage.setItem('doctorId', this.state.doctorId);
                localStorage.setItem('patientId', this.state.patientId);
                localStorage.setItem('consultation_id', response.data.result.video_consultation.consultation_id);
                localStorage.setItem('isConnected', "1");
                localStorage.setItem('passCode',response.data.result.video_consultation.pd_passcode);
                if (type === 'd') {
                    this.setState({
                        name: this.state.doctor_name,
                        type: 'd'
                    })
                    var conv = "conv_" + conversationId;
                    window.history.replaceState(null, '', window.encodeURI(`/${this.state.type}/${conv}/${this.state.name}${window.location.search || ''}`));
                   
                } else if (type === 'p') {
                    this.setState({
                        name: this.state.patient_name,
                        type: 'p'
                    })
                }
                localStorage.setItem('conversation_id', conversationId);
                localStorage.setItem('name', this.state.name);
                localStorage.setItem('type', type);
                localStorage.setItem('isDoctorEndCall' , '0');
                axios.get(Strings.title.urls.baseUrl + '/consent/getConsentSignedStatus/' + conversationId)
                    .then((response) => {

                        if (!response.data.result) {
                            axios.post(Strings.title.urls.baseUrl + '/consent/getConsentForm')
                                .then((response) => {
                                    this.setState({
                                        consentList: response.data.result.content.split("</li>").filter((item) => item !== ""),
                                        consentVersion: response.data.result.consent_version
                                    })
                                })
                                .catch((error) => {
                                    console.log(error); console.log('Error connecting server');
                                });
                        } else {
                            this.setState({
                                permissionPage: true
                            });
                        }
                    })
                    .catch((error) => {
                        console.log(error); console.log('Error connecting server');
                    });
            })
            .catch((error) => {

                console.log("concentPage.js ========>  "+error); console.log('Error connecting server');
            });
        }
    }

    redirectToSteps = () => {

            this.setState({
                permissionPage: true
            });
            var data = {

                conversation_id: conversationId,
                entity_id: this.state.entityId,
                doctor_id: this.state.doctorId,
                patient_id: this.state.patientId,
                policy_revision: this.state.consentVersion,
                method: "ONLINE"

            }

            axios.post(Strings.title.urls.baseUrl + '/consent/setConsentAgreement', data)
                .then().catch((error) => {
                    console.log(error); console.log('Error connecting server');
                });
    }


    isCheck = () => {
        if (document.getElementById('terms_and_conditions').value === 'off') {
            this.setState({
                isDisabled: false,
                check: "on"
            })
        }
        else if (document.getElementById('terms_and_conditions').value === 'on') {

            this.setState({
                isDisabled: true,
                check: "off"
            })
        }
    }



    isNumeric = (event) => {
        if ((event.keyCode >= 48 && event.keyCode <= 57))
            return true;
        else
            return false;
    }

    render() {

        
    if(this.state.ErrorPage){
       return(
       <div style={{paddingRight: "10%" , paddingTop:"5%", paddingLeft:"10%" }}>
        <h1 style={{ color: "white", fontSize: "350%", fontFamily: "Serif", margin: "1%" }}>Your video consultation link seems to be broken, please ask your doctor to share the link via WhatsApp.</h1>
      </div>
       );
    }
        if (this.state.type === 'd') {
            return (
                <VideoApp />
            );
        }

        if (this.state.permissionPage) {
            return (
                < Upload />
            );
        }
        // if (this.state.name === null && this.state.consentList === null)
        //     this.ConsentFormConditions();

        if (this.state.name !== null && this.state.consentList !== null) {
            return (
                <Container >
                    <Main >
                        <div className="card" style={{ position: "fixed", color: 'black' }}>
                            <div className="question" style={{ textAlign: "center" }}>
                                <h1>Consent for Tele-consultation  </h1>
                            </div>
                            <br /><br />
                            <div style={{ padding: "1%" }}></div>
                            <form>
                                <div className=" content col-xs-12 col-sm-12 col-md-12 question">
                                    <div className="col-md-12 col-sm-12 col-xs-12" style={{ paddingLeft: "0px" }}>
                                        <div className="col-md-12 col-sm-12 col-xs-12" style={{ paddingLeft: "3%" }}>
                                            <div className="col-md-6 col-sm-6 col-xs-12" style={{ marginBottom: "2%" }}>

                                                <label>Name</label> <input type="text"
                                                    className="form-control" style={{ backgroundColor: "white" }} placeholder={this.state.patient_name} readOnly />

                                            </div>
                                            <div className="col-md-6 col-sm-6 col-xs-12" style={{ marginBottom: "2%" }}>

                                                <label>Date of Birth</label> <input type="text"
                                                    className="form-control " style={{ backgroundColor: "white" }} placeholder={this.state.patient_age} readOnly />

                                            </div>
                                        </div><br />
                                        <div className="col-md-12 col-sm-12 col-xs-12" style={{ paddingLeft: "3%" }}>
                                            <div className="col-md-6 col-sm-6 col-xs-12" style={{ marginBottom: "2%" }}>

                                                <label>Gender</label> <input type="text"
                                                    className="form-control" style={{ backgroundColor: "white" }} placeholder={this.state.patient_sex} readOnly />

                                            </div>
                                            <div className="col-md-6 col-sm-6 col-xs-12" style={{ marginBottom: "2%" }}>

                                                <label>Registered phone number</label> <input type="text" id="phoneNum" name="phoneNo"
                                                    className="form-control" style={{ backgroundColor: "white" }} placeholder={this.state.patient_phone} readOnly />

                                             </div>
                                        </div>
                                    </div>
                                    <div className="content col-xs-12 col-sm-12 col-md-12" style={{ marginTop: "-4%" }} >
                                        <div className="col-xs-0 col-sm-2 col-md-3"></div>
                                        <div className="col-xs-12 col-sm-8 col-md-6" style={{ textAlign: "left", width: "100%", fontSize: "180%" }} >
                                            <ol style={{ paddingLeft: "0%", margin: "3%", width: "100%", paddingRight: "3%" }}>
                                                {this.state.consentList.map(consent => (
                                                    <li key={consent.substring(consent.indexOf("<li>") + 4, consent.length).toString()}>  {consent.substring(consent.indexOf("<li>") + 4, consent.length)} </li>
                                                ))}
                                            </ol>
                                            <div style={{ textAlign: "left", marginLeft: "2%" }}><input type="checkbox" id="terms_and_conditions" value={this.state.check} onChange={this.isCheck}  /><label htmlFor="terms_and_conditions">&nbsp;&nbsp;I {this.state.name},&nbsp;agree to participate in online consultation </label></div>
                                            <div style={{ padding: "3%", textAlign: "center" }} >
                                                <button type="submit" className="btn btn-primary" onClick={this.redirectToSteps} name="terms" id="terms" disabled={this.state.isDisabled} >
                                                    Consent and Proceed</button>
                                            </div>
                                        </div>
                                        <div className="col-xs-0 col-sm-2 col-md-3"></div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </Main>
                </Container>
            );
        } else {
            return (
                <div></div>
            );
        }
    }
}

export function useFull() {
    var DPName = {
        patient: patientname,
        doctor: doctorName
    };
    return DPName;
}