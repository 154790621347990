import React from 'react';
import Strings from '../../Strings';
import { useAudioInputDevices, useVideoInputDevices } from '../MenuBar/DeviceSelector/deviceHooks/deviceHooks';
import {
    isIOS,
    isMacOs,
    isMobile,browserName
  } from "react-device-detect";
import UnsupportedBrowserWarning from '../UnsupportedBrowserWarning/UnsupportedBrowserWarning';
import { VideoProvider } from '../VideoProvider';
import ErrorDialog from '../ErrorDialog/ErrorDialog';
import App from '../../App';
import useConnectionOptions from '../../utils/useConnectionOptions/useConnectionOptions';
import { useAppState } from '../../state';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Alert } from 'react-bootstrap';

  const VideoApp = () => {
    const { error, setError } = useAppState();
    const connectionOptions = useConnectionOptions();
  
    return (
        <UnsupportedBrowserWarning>
            <VideoProvider options={connectionOptions} onError={setError}>
                <ErrorDialog dismissError={() => setError(null)} error={error} />
                <App />
            </VideoProvider>
        </UnsupportedBrowserWarning>
    );
  };

export default function CheckPermission() {
    
const audioInputDevices = useAudioInputDevices();
const videoInputDevices = useVideoInputDevices();
    
  
    return (
        <div>
                {audioInputDevices.length >= 1 && videoInputDevices.length >= 1 ? (
                  <VideoApp/>
                ) : (
                    <div className="col-lg-12 col-md-12 col-sm-12 text-center" style={{fontSize:"200%",marginTop:"20%"}}> 
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <p> Is seems the camera and microphone permissions are not granted/allowed </p>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <p>Please allow them to contine and to know howv to enable permission click on the help button </p>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12">
                           <a href={Strings.title.browserPermissionHelpPages.chrome}> <button className="btn btn-info">Help</button></a>
                        </div>
                    </div>
                )}
        </div>
    );
}

