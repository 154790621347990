import React, { FormEvent, useState, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Toolbar from '@material-ui/core/Toolbar';
import Strings from '../../Strings.js';
import { useAppState } from '../../state';
import { useParams } from 'react-router-dom';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import ConcentPage, { useFull } from '../../components/VideoSupportPage/ConcentPage';
import FlipCameraButton from './FlipCameraButton/FlipCameraButton';
import StartCallButton from './StartCallButton.js';
import { ContactSupportOutlined } from '@material-ui/icons';
import { browserName, browserVersion, mobileModel, osName, osVersion, isWindows, isMobile, isBrowser } from 'react-device-detect';
const axios = require('axios');
var ButtonStartCall = false;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.background.default,
      height: '43px',
    },
    toolbar: {
      [theme.breakpoints.down('xs')]: {
        padding: 0,
      },
    },
    rightButtonContainer: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 'auto',
    },
    form: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      [theme.breakpoints.up('md')]: {
        marginLeft: '2.2em',
      },
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      maxWidth: 80,
    },
    loadingSpinner: {
      marginLeft: '1em',
      marginBottom: '2.5em',
    },
    displayName: {
      margin: '1.1em 0.6em',
      minWidth: '200px',
      fontWeight: 300,
      fontSize: '100%'
    },
    joinButton: {
      margin: '1em',
      height: '20%',
      marginBottom: '2.7em',
    },
  })
);


export default function MenuBar() {
  const classes = useStyles();
  //const { URLRoomName } = useParams();
  const { user, isFetching } = useAppState();
  const { isConnecting, connect } = useVideoContext();
  const roomState = useRoomState();

  const [,] = useState<string>(user?.displayName || '');
  //const [, setRoomName] = useState<string>('');

  /* useEffect(() => {
    if (URLRoomName) {
      setRoomName(URLRoomName);
    }
  }, [URLRoomName]); */

  var deviceInfo = {
    browserName: browserName,
    mobileModel: mobileModel,
    browserVersion: browserVersion,
    osName: osName,
    osVersion: osVersion,
    isMobile: isMobile,
    isBrowser: isBrowser,
    isWindows: isWindows,
  }

  var test = useFull();
  window.onload = function () {
    console.log("on lOad");

  };
  const callHandler = () => {
    localStorage.setItem('isConnected', "0");
    handleSubmit();
  }
  function handleSubmit() {
    ButtonStartCall = true;
    if (localStorage.getItem('isConnected') === '0') {
      localStorage.setItem('isConnected', "1");


      var dec_url = window.location.href;
      var URL = decodeURIComponent(dec_url);
      var type: string | null = null;
      var conversationId: string | null = null;

      if (URL.indexOf("/p/") !== -1) {
        type = URL.substring(URL.lastIndexOf("/p/") + 1, URL.lastIndexOf("/p/") + 2);

      } else if (URL.indexOf("/d/") !== -1) {
        type = URL.substring(URL.lastIndexOf("/d/") + 1, URL.lastIndexOf("/d/") + 2);

      }
      conversationId = URL.substring(URL.lastIndexOf("conv_") + 5, URL.lastIndexOf("conv_") + 5 + 10);

      var name = "";
      if (URL.indexOf("/p/") !== -1) {
        name = localStorage.getItem('name') + "";
      } else if (URL.indexOf("/d/") !== -1) {
        name = localStorage.getItem('doctorName') + "";
      }
      name = name.replace(".", "");
      name = name.replace(/\s/g, '_');
      axios.get(Strings.title.urls.baseUrl + '/room/getToken/' + conversationId + '/' + name)
        .then((response: { data: { jwt: any; identity: any; result: any; room_sid: any }; }) => {
          console.log(response.data.room_sid);
          connect(response.data.jwt).then(() => {
            ButtonStartCall = false;
            axios.post(Strings.title.urls.baseUrl + '/Participants/Room/' + conversationId + '/getParticipantWithDeviceInfo/' + name + '/' + type, { "deviceInfo": deviceInfo })
              .then().catch((error: any) => {
                console.log('Error connecting server')
              });


            axios.post(Strings.title.urls.baseUrl + '/Participants/connectedParticipantsWithDeviceInfo/' + conversationId, { "deviceInfo": deviceInfo })
              .then().catch((error: any) => {
                console.log('Error connecting server')
              });

          })
        })
        .catch((error: any) => {
          console.log("MenuBar : "+error);
        });
    }
    return (
      <div></div>
    );
  };

  return (

    <AppBar className={classes.container} position="static">
      <Toolbar className={classes.toolbar}>

        {
          localStorage.getItem('isConnected') === '1' ?
            roomState === 'disconnected' ? (
              <form className={classes.form} >
                <StartCallButton
                  style={{ marginBottom: "30%" }}
                  className={classes.joinButton}
                  type="button"
                  color="primary"
                  variant="contained"
                  id="startCall"
                  onClick={callHandler}
                >
                  {Strings.title.label.buttonMsg}
                </StartCallButton>
                {(isConnecting || isFetching) && <CircularProgress className={classes.loadingSpinner} />}
              </form>
            ) : (
              <h3 style={{ fontSize: "170%", marginLeft: "1%", marginBottom: "2.8em" }}>{/*Strings.title.twilio.onVideoMsg+" "}{test.doctor+" and "}{test.patient*/}</h3>
            )
            :
            roomState === 'disconnected' ? (
              <div>
                {handleSubmit()}
              </div>
            ) : (
              <h3 style={{ fontSize: "170%", marginLeft: "1%", marginBottom: "2.8em" }}>{/*Strings.title.twilio.onVideoMsg+" "}{test.doctor+" and "}{test.patient*/}</h3>
            )
        }


        
        <div className={classes.rightButtonContainer} style={{ marginBottom: "1.5%" }}  >

          {/* <LocalAudioLevelIndicator />
          <ToggleFullscreenButton />
          <Menu /> */}
        </div>

      </Toolbar>
    </AppBar>
  );
}


