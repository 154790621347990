import { LocalAudioTrack } from 'twilio-video';
import { useCallback } from 'react';
import useIsTrackEnabled from '../useIsTrackEnabled/useIsTrackEnabled';
import useVideoContext from '../useVideoContext/useVideoContext';
import Strings from '../../Strings';
import {browserName,browserVersion,mobileModel,osName,osVersion,isWindows, isMobile , isBrowser} from 'react-device-detect';

export default function useLocalAudioToggle() {
  const { localTracks } = useVideoContext();
  const audioTrack = localTracks.find(track => track.kind === 'audio') as LocalAudioTrack;
  const isEnabled = useIsTrackEnabled(audioTrack);
  const axios = require('axios');
 // Testing code start
  //audioTrack.disable();
  // testing code end
  var pId = "" , id ,eventName ;
  var type= localStorage.getItem('type');
  const toggleAudioEnabled = useCallback(() => {
    if (audioTrack) {
      audioTrack.isEnabled ? audioTrack.disable() : audioTrack.enable();
      audioTrack.isEnabled ? eventName = "audio-track-added" : eventName = "audio-track-removed";
      type === 'p' ?  pId = localStorage.getItem('patientId')+"" : pId = localStorage.getItem('doctorId')+"";
     var deviceInfo = {
       browserName  :browserName,
       mobileModel  : mobileModel,
       browserVersion:browserVersion,
       osName: osName,
       osVersion: osVersion,
       isMobile:isMobile,
       isBrowser: isBrowser,
       isWindows: isWindows,
      }
      var description ={
        identity : localStorage.getItem('name')
      }
      
      var data = {
        conversation_id: localStorage.getItem('conversation_id')+'',
        description: description,
        deviceInfo: deviceInfo,
        event: eventName,
        participant_id: pId,
        participant_type: type
    };
      axios.post(Strings.title.urls.baseUrl+'/logs/setParticipantLog',data).then((response : any)=>{
        console.log(response.data);  
      }).catch((error : any) =>{
        console.log(error);
      })
      
    }
  }, [audioTrack]);

  return [isEnabled, toggleAudioEnabled] as const;
}
