import React from 'react';
import { styled } from '@material-ui/core/styles';
import Strings from '../../Strings';
import '../../css/main.css';
import moment from 'moment';
import { ProgressBar } from 'react-bootstrap';
import api from './Request';
import '../../css/main2.css';
import UnsupportedBrowserWarning from '../UnsupportedBrowserWarning/UnsupportedBrowserWarning';
import { VideoProvider } from '../VideoProvider';
import ErrorDialog from '../ErrorDialog/ErrorDialog';
import App from '../../App';
import { useAppState } from '../../state';
import useConnectionOptions from '../../utils/useConnectionOptions/useConnectionOptions';
import VideoSupportPage from './VideoSupportPage';
import { BrowserView, isMobile, MobileView } from 'react-device-detect';

const axios = require('axios');


const Container = styled('div')({
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
});

const Main = styled('main')({
    overflow: 'hidden',
});

const VideoApp = () => {
    const { error, setError } = useAppState();
    const connectionOptions = useConnectionOptions();

    return (
        <UnsupportedBrowserWarning>
            <VideoProvider options={connectionOptions} onError={setError}>
                <ErrorDialog dismissError={() => setError(null)} error={error} />
                <App />
            </VideoProvider>
        </UnsupportedBrowserWarning>
    );
};

var totalSize = 0;
var reset = false;
var sharedRecordDetails = "";
var recordData = "";
var recordList = [];
class Upload extends React.Component {
    filePatient = null;
    constructor() {

        super();

        this.state = {
            uploadPage: false,
            uploadFiles: false,
            uploadingStatus: "Files to be uploaded",
            fileArray: [],
            disable: true,
            fileNames: [],
            isSubmit: false,
            uploadPercentage: 0,
            redirectToVideoPage: false,
            isUploadPage : false,
            fileSelected : false
        }

        this.buttonSubmit = null;

        this.setbuttonSubmitRef = element => {
            this.buttonSubmit = element;
        };
        localStorage.setItem('isConnected', "1");
        //API calling
        var URL = window.location.href;
        var conversationId = URL.substring(URL.lastIndexOf("conv_") + 5, URL.lastIndexOf("conv_") + 15);
        var now = moment().format();
        console.log("Upload.js         ======>     "+Strings.title.urls.baseUrl + '/videoConsultation/getVideoConsultation/' + conversationId);
        axios.get(Strings.title.urls.baseUrl + '/videoConsultation/getVideoConsultation/' + conversationId)
            .then((response) => {
                sharedRecordDetails = {
                    doctorId: response.data.result.doctor_details.doctor_id,
                    consultationId: response.data.result.video_consultation.consultation_id
                }
                recordData = {
                    patientID: response.data.result.video_consultation.patient_id,
                    hospName: "-",
                    hostVaultName: "-",
                    viewJSONValue: {
                        "Patient Name": response.data.result.video_consultation.name,
                        "Treating Doctor": response.data.result.doctor_details.doctor_name,
                        "Date of Record": now
                    },
                    fileLocation: "PLUS_CLOUD",
                    relationshipID: 1,
                    createdBy: response.data.result.video_consultation.patient_id,
                    modifiedBy: response.data.result.video_consultation.patient_id
                }
            })
            .catch((error) => {

                console.log("Upload.js         ======>     "+error);
            });
    }

    redirectToVideo = () => {
        this.setState({
            redirectToVideoPage: true
        })
    }

    handleClick = (id) => {
        document.getElementById(id).disabled = false
        document.getElementById(id).style.borderColor = "black"

    }

    onFileChange = event => {
        console.log(event.target.files);
        if (this.state.fileArray.length >= 5) {
            event.target.value = null;
            reset = false;
        }
        let newFile = event.target.files;

        //let newFileType = event.target.files;
    
        for (let i = 0; i < newFile.length; i++) {
            if (this.state.fileArray.length + i >= 5){
                alert("Only max 5 file are allowed at a time");
                break;
            }else{
            if (newFile[i].size < (5 * 1024 *1024)) {
                if (this.state.fileArray !== undefined && this.state.fileArray !== null) {
                    this.setState(prevState => ({
                        fileArray: [...prevState.fileArray, newFile[i]],
                        fileNames: [...prevState.fileNames, newFile[i].name]
                    }));
                } else {
                    this.setState({
                        fileArray: [newFile[i]],
                        fileNames: [newFile[i].name]
                    })
                }
                this.setState({
                    fileSelected : true
                })
            } else {
                event.value = "";
                alert(newFile[i].name + " should be less than "+ (5 * 1024 *1024) +"!");
            }
        } 
         
        }
    };

    fileData = () => {

        if (this.state.isSubmit) {
            const { uploadPercentage } = this.state;
            return (
                <div>
                    {uploadPercentage > 0 && <ProgressBar now={uploadPercentage} active label={`${uploadPercentage}%`} />}
                </div>
            );
        }
        if (this.state.fileArray) {
            return (
                <div>
                    {
                        this.state.fileArray === null ? null :
                            this.state.fileArray.map((file,index) => (

                                <div className="col-md-3 col-xs-12 col-sm-6" style={{ height: "100%", overflowY: "hidden", fontSize: "150%"}}>
                                    <div className="col-md-6 col-xs-6 col-sm-6" >
                                         {this.Greeting(file,index)} 
                                    </div>   
                                    <div className="col-md-6 col-xs-6 col-sm-6" >
                                        <button className="fa fa-trash-o fa-2x" style={{float:"right",backgroundColor : "transparent",border : "none"}} onClick={() => this.deleteFile(index)}></button>
                                    </div> 
                                    <div className="col-md-12 col-xs-12 col-sm-12" style={{margin:"2%"}} >
                                         
                                            <ul className="list-group" style={{position:"inherit",width:"100%",marginTop:"30%"}}>
                                                <span >  <input disabled="true" className="list-group-item prefix" id={file.name} style={{ width: "80%", float: "left" }} name="editedName" placeholder={file.name} /></span>
                                                  <a onClick={() => this.handleClick(file.name)} style={{ width: "20%", float: "right", background: "none", border: "none" }}>
                                                <i style={{ marginLeft: "33%",marginTop:"3%" }} className="fa fa-pencil fa-fw fa-2x" aria-hidden="true" ></i></a>
                                            </ul>
                                    </div>
                                </div>
                            ))
                    }
                </div>
            );
        } else {
            return (
                <div>
                </div>
            );
        }
    };
    deleteFile = (index) =>{
        console.log(index);
        var tempFile = [...this.state.fileArray];
        var tempFileName = [...this.state.fileNames];
        tempFile.splice(index, 1);
        tempFileName.splice(index, 1);
        this.setState({
            fileArray : tempFile,
            fileNames : tempFileName
        });
        if(tempFile.length === 0){
            this.setState({
                fileSelected : false
            })
        }
    }
    Greeting = (file,index) => {
        var type = file.type;
        console.log(file);
        switch (type) {
            case "image/png":
            case "image/jpg":
            case "image/jpeg":
                return (
                    <div className="col-md-12 col-sm-12 col-lg-12">
                    <i className="fa fa-picture-o fa-5x card-img-top" aria-hidden="true" style={{ marginBottom: "5%", marginTop: "5%" }}></i>
                    </div>
                );
            default:
        }

        if (type === "application/pdf") {
            return (
                
                <div className="col-md-12 col-sm-12 col-lg-12">
                <i className="fa fa-file-pdf-o fa-5x card-img-top" aria-hidden="true" style={{ marginLeft: "17%", padding: "5%" }}></i>
              </div>  
            );
        } else if (type === "audio/mpeg") {
            return (
               
                <div className="col-md-12 col-sm-12 col-lg-12">
                <i className="fa fa-file-audio-o fa-5x card-img-top" aria-hidden="true" style={{ marginLeft: "17%", padding: "5%" }}></i>
             </div>
            );
        }
    }

    disableButton = () => {
        // Focus the text input using the raw DOM API
        if (this.buttonSubmit) this.buttonSubmit.setAttribute("disabled", "disabled");
    };

    isUploadPageFunction = () => {
        console.log('aaya')
        this.setState({
            isUploadPage: true
        })
        console.log(this.state.isUploadPage)
    }
    redirectToUpload = () => {
        //this.refs.btn.setAttribute("disabled", "disabled");
        this.disableButton();

        this.setState({
            isSubmit: true,
            uploadingStatus: "Uploading..."
        })
        this.state.fileArray.forEach((file, i) => {
            totalSize += file.size
            if (file.name !== document.getElementById(file.name).value && document.getElementById(file.name).value !== "") {
                this.state.fileNames[i] = document.getElementById(file.name).value + file.name.substring(file.name.lastIndexOf("."), file.name.length);
            }
        });

        this.state.fileArray.forEach((file, i) => {

            var fd = new FormData();
            fd.append('recordData', JSON.stringify(recordData));
            fd.append('fileName', this.state.fileNames[i]);
            fd.append('recordFile', file);
            fd.append('sharedRecordDetails', JSON.stringify(sharedRecordDetails));

            const options = {
                onUploadProgress: (progressEvent) => {
                    var { loaded, total } = progressEvent;
                    total = totalSize;

                    let percent = Math.floor((loaded * 100) / total)
                    console.log(`${loaded}kb of ${total}kb | ${percent}%`);

                    if (percent < 100) {
                        this.setState({ uploadPercentage: percent })
                    }
                }
            }

            api.post(Strings.title.urls.baseUrlForLAN + "/api/mypd/v3/upload_record", fd, options, {
            })
                .then(res => { // then print response status

                    this.setState({ avatar: res.data.url, uploadPercentage: 100 }, () => {
                        setTimeout(() => {
                            this.setState({ uploadPercentage: 0 })
                        }, 1000);
                    })

                    console.log(res)
                })
        })
        alert("Documents Uploaded Successfully!");

        this.setState({
            redirectToVideoPage: true
        })
    }


    render() {
        if (this.state.redirectToVideoPage) {
            return (
                 <VideoSupportPage />

            );
        }
        
        if(!this.state.isUploadPage){
        
            return (

                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 card" style={{margin:"23%",width:"50%"}}>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center" style={{marginTop:"3%"}}>
                            <button className="btn btn-primary" onClick={this.isUploadPageFunction} >Upload Medical Record</button>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center" style={{margin:"3% 0"}}>
                           <strong style={{color:"black"}}>OR</strong>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center" style={{marginBottom:"3%"}}>
                            <button className="btn btn-primary" onClick={this.redirectToVideo}>Start Video Consultation</button>
                        </div>
                </div>
            );
        }

        return (
            <Container>
                <Main>
                     
                   
                    <div className="card" style={{ position: "fixed", color: 'black', height: '90%', width: "80%", marginLeft: "10%", overflowY: "scroll" }}>
                        <div className="question" style={{ textAlign: "center", fontFamily: "Georgia" }}>
                            <h1 style={{ fontSize: "230%" }}>Upload medical records </h1>
                        </div>
                        
                            <h1 style={{ fontSize: "150%",textAlign: "center", fontFamily: "Arial" ,color : "red", fontWeight : "bold" }}>You can upload a total of 5 files of type ( .pdf, .png, .jpeg, .jpg ), size upto 5MB. </h1>
                         
                        <div>
                        {/* <div className="col-xs-12 col-sm-12 col-md-12 question1">
                                <button className="new-button"  onClick={this.redirectToVideo} style={{  marginRight: "2%", float: "right", marginBottom: "2%", border: "none" }}>
                                   <b>Start Video Consultation</b> 
                                  </button>
                            </div> */}
                            
                            <div className="col-xs-12 col-sm-12 col-md-12 question1">
                                <form className="md-form" >
                                    {!isMobile ? (
                                <BrowserView>
                                        {/* <div className="btn btn-info btn-sm " style={{ width: "100%" }}>
                                            <input type="file" multiple onChange={this.onFileChange} accept="image/*, audio/* , application/pdf" />

                                        </div> */}
                                        <div style={{ width: "100%",textAlign: "center" }}>
                                            {/* <input style={{width:"100%",fontFamily:"Serif",fontSize:"130%",backgroundColor:"#4db9f7"}}type="file" multiple onChange={this.onFileChange} accept="image/*, audio/* , application/pdf" /> */}
                                            <div className="button-wrap">
                                                <label className="new-button" htmlFor="upload" >Choose File
                                                    <input id="upload" style={{ display : "none"}} type="file" className="fileBrowser" multiple onChange={this.onFileChange} accept="image/*, audio/* , application/pdf" />
                                                </label>
                                                </div>
                                                </div>
                                              
                                    </BrowserView>
                                    ) : (
                                    <MobileView>
                                        <div style={{ width: "100%",textAlign: "center" }}>
                                            {/* <input style={{width:"100%",fontFamily:"Serif",fontSize:"130%",backgroundColor:"#4db9f7"}}type="file" multiple onChange={this.onFileChange} accept="image/*, audio/* , application/pdf" /> */}
                                            <div className="button-wrap">
                                                <label className="new-button" for="upload" >Choose File
                                                    <input id="upload" style={{ display : "none"}} type="file"  multiple onChange={this.onFileChange} accept="image/*, audio/* , application/pdf" />
                                                </label>
                                                </div>
                                                </div>
                                              
                                                
                                    </MobileView>
                                    )}
                                </form>
                            </div>
                            <div className="col-xs-12 col-sm-3 col-md-3 question1" style={{ padding: "2%" }}></div>

                        </div>

                        <div className="col-xs-12 col-sm-12 col-md-12 question1"  >
                            <h5 style={{ fontFamily: "Georgia" }}>{this.state.fileSelected ? this.state.uploadingStatus : ''}</h5>

                            <div className="row">
                                {this.fileData()}
                            </div>
                        </div>

                        <div style={{

                            width: "100%",
                            bottom: 0,
                            left: 0,
                            overflow: "none"
                        }}>
                            <div className="col-xs-12 col-sm-12 col-md-12 card-footer question1">
                               {this.state.fileSelected ?(
                                    <button ref={this.setbuttonSubmitRef} className="btn btn-primary" style={{ width: "100%", marginTop: "10%" }} onMouseDown={this.redirectToUpload} name="terms" id="terms"  >
                                    Upload and Start Video Consultation </button>
                               ) :(
                                    <button ref={this.setbuttonSubmitRef} className="btn btn-primary" style={{ width: "100%", marginTop: "10%" }} onClick={this.redirectToVideo} name="terms" id="terms"  >
                                    Start Video Consultation </button>
                               )} 
                               </div>

                                    
                            {!isMobile ? (                              
                               <BrowserView>
                           <div className="col-xs-12 col-sm-12 col-md-12 row" style={{ width:"75%", fontSize: "100%" }}>
                       <strong><i  className="fa fa-lock fa-2x" style={{ width:"100%",marginLeft:"20%"}}>&nbsp;&nbsp;<b>Data shared is secure, encrypted and confidential.</b></i> </strong>
                       
                    </div>
                                </BrowserView>
                            ) : (
                                <MobileView>
                                
                                  <div className="col-xs-12 col-sm-12 col-md-12 text2" style={{ width:"75%", marginTop: "1%", fontSize: "70%" }}>
                       <strong><i  className="fa fa-lock fa-2x" style={{ width:"100%",marginLeft:0}}>&nbsp;&nbsp;<b>Data shared is secure, encrypted and confidential.</b></i> </strong>
                       
                    </div>
                                </MobileView>
                            
                        )}
                        </div>

                    </div>
                </Main>
            </Container>
        )
    }
}

export default Upload