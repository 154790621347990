import React from 'react';
import { styled } from '@material-ui/core/styles';
import Strings from '../../Strings';
import api from './Request';
import '../../css/main2.css';
import '../../css/playstore.css';
import Modal from 'react-awesome-modal';
import Logo from './logo.png';
import App from '../../App';
import UnsupportedBrowserWarning from '../UnsupportedBrowserWarning/UnsupportedBrowserWarning';
import { VideoProvider } from '../VideoProvider';
import ErrorDialog from '../ErrorDialog/ErrorDialog';
import { useAppState } from '../../state';
import useConnectionOptions from '../../utils/useConnectionOptions/useConnectionOptions';
import { BrowserView, MobileView, TabletView,isMobileOnly, isMobile } from 'react-device-detect';
import CheckPermission from './CheckPermission';
const axios = require('axios');

const Container = styled('div')({
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
});
var type , participantRejoin = false;
class FeedBack extends React.Component {
    constructor() {
        super();
     //   window.history.replaceState(null, '', window.encodeURI(`/${this.state.type}/${conv}/${this.state.name}${window.location.search || ''}`));
      type = localStorage.getItem('type');;
     var conversationId = localStorage.getItem('conversation_id');
     //URL.substring(URL.lastIndexOf("conv_") + 5, URL.lastIndexOf("conv_") + 5 + 10);
     var conv = "conv_" + conversationId;
     var name =  localStorage.getItem('name')+"";   
     name = name.replace(".", "");
     name = name.replace(/\s/g, '_');
     console.log(localStorage.getItem('conversation_id'));
     console.log(localStorage.getItem('name'));
     console.log(localStorage.getItem('type')+ "   "+ type);
     window.history.replaceState(null, '', window.encodeURI(`/${type}/${conv}/${name}${window.location.search || ''}`));
    
        axios.get(Strings.title.urls.baseUrl + '/feedback/getQuestions/')
            .then((response) => {

                this.setState({
                    questionList: response.data.result
                })
                console.log(this.state.questionList);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    state = {
        questionList: [],
        callDisconnected : true,
        visible : true,
        thankYouPage : false,
        participantRejoin: false,
        isResponseEmpty: true
    }
     VideoApp = () => {
        const { error, setError } = useAppState();
        const connectionOptions = useConnectionOptions();
    
        return (
            <UnsupportedBrowserWarning>
                <VideoProvider options={connectionOptions} onError={setError}>
                    <ErrorDialog dismissError={() => setError(null)} error={error} />
                    <App />
                </VideoProvider>
            </UnsupportedBrowserWarning>
        );
    };
    skipHandler = () => {
        this.setState({
            visible : false,
            thankYouPage : true
        })
        
    }
    close = () =>{
        window.opener = null;
        window.open("", "_self");
        window.close();
    }
    submitHandler = () =>{
        var answers =[];
         var  details = {
            consultation_id : localStorage.getItem('consultation_id'),
            patientId : localStorage.getItem('patientId'),
            doctorId  : localStorage.getItem('doctorId'),
            formId : this.state.questionList[0].form_id
        }
        this.state.questionList.forEach(question => {
            var ans = "";
            if(question.question_type === "RADIO"){
            var rating = document.getElementsByName(question.question_id +"stars");
          
            rating.forEach(rate => {
                if(rate.checked){
                    ans  =6-  rate.value;
                }
            }

            )
            }else if(question.question_type === "TEXT")
            ans =document.getElementsByName(question.question_id +"text")[0].value;

            var answer ={
                question : question.question,
                question_id : question.question_id,
                answer : ans+""
            }
            answers.push(answer);
        });
       

        api.post(Strings.title.urls.baseUrl + "/feedback/setPatientFeedback",  { 
            details : details,
            answers : answers
        }).then((response) => {
            console.log(response.data);
        
        })
        .catch((error) => {
            console.log(error);
        });

        this.setState({
            visible : false,
            thankYouPage : true
        })
    }
    
    callHandler = () => {
        localStorage.setItem('isConnected' , '0');
        console.log("Call handler");
         this.setState({
            participantRejoin : true
         })
      
      
    }
    onValueChange = () => {
        this.setState({
          isResponseEmpty: false
        });
      }
    radioQuestion = (question) => {
        return (
            <div>
                <div className="form1 col-xs-12 col-sm-12 col-md-12" >
                    <h5 style={{ fontFamily: "Georgia" }}>
                                    {question.question}</h5>
                </div>
                <div className="form1 col-xs-12 col-sm-12 col-md-12" style={{ textAlign: "center", marginTop: "0%" }} >
                    <fieldset className="stars">
                        <input type="radio" name={question.question_id +"stars"} id={question.question_id +"star1"}  value="1"  onTouchStart="ontouchstart" onChange={this.onValueChange} />
                        <label className="fa fa-star" htmlFor={question.question_id +"star1"}></label>
                        <input type="radio" name={question.question_id +"stars"}  id={question.question_id +"star2"} value="2"  ontouchstart="ontouchstart" onChange={this.onValueChange} />
                        <label className="fa fa-star" htmlFor={question.question_id +"star2"}></label>
                        <input type="radio" name={question.question_id +"stars"}  id={question.question_id +"star3"} value="3"  ontouchstart="ontouchstart" onChange={this.onValueChange}/>
                        <label className="fa fa-star" htmlFor={question.question_id +"star3"}></label>
                        <input type="radio" name={question.question_id +"stars"}  id={question.question_id +"star4"} value="4"  ontouchstart="ontouchstart" onChange={this.onValueChange}/>
                        <label className="fa fa-star" htmlFor={question.question_id +"star4"}></label>
                        <input type="radio" name={question.question_id +"stars"}  id={question.question_id +"star5"} value="5"  ontouchstart="ontouchstart" onChange={this.onValueChange} />
                        <label className="fa fa-star" htmlFor={question.question_id +"star5"}></label>
                    </fieldset>
                </div>  
            </div>
        );
    }

    textQuestion = ( question) => {
        return (
            <div>
                
                <div className="col-xs-12 col-sm-12 col-md-12" >
                         <div className="form1">
                                <h5 style={{ fontFamily: "Georgia" }} >
                          {question.question}
                             </h5></div>
                            <div className="form1">
                                <textarea className="form-control" name={question.question_id +"text"} rows="3" style={{width : "70%"}} onChange={this.onValueChange} ></textarea>
                            </div>
                        </div>
            </div>
        );
    }
    render() {
        if(type === 'd'){
           return(
            <CheckPermission/>
           );
        }
        if(this.state.participantRejoin){
        return(
            <CheckPermission/>
           );
       }
        if(this.state.thankYouPage){
            return (
                <div className="  col-xs-12 col-md-12 col-sm-12">
                      <div className="form1">
                      <h1>Thank you for choosing Purpledocs</h1></div>
                   
                  </div>
            );
        }
        return (
            
            <section>
                {isMobileOnly  ? (
                <MobileView>
            <Modal 
            visible={this.state.visible}
            width = "90%"
            effect="fadeInUp"
            onClickAway={this.skipHandler}
            >
            <div className="card2 " style={{ display:"block",width:"100%", paddingBottom: "2%", color: 'black'}} >
                
                    <div className=" col-xs-12 col-sm-12 col-md-12" style={{ backgroundColor: "#d6e4e0d3" }}>
                        <div className=" col-xs-12 col-sm-6 col-md-6 ">
                            <img src={Logo} style={{ width: "60%", height: "45%", marginTop: "5%", marginBottom: "2%" }}></img>
                        </div>
                        <div className=" col-xs-12 col-sm-6 col-md-6 ">
                            <h3 style={{ fontFamily: "comic sans", fontStyle: "italic", color: "black", float: "right" }}>
                                Your Feedback</h3>
                        </div>
                    </div>

                    
                    {this.state.questionList.map(question => (

                        question.question = question.question.replace("##DOCTORNAME##", localStorage.getItem('doctorName')),
                        question.question_type === "RADIO" ?
                     <div> {this.radioQuestion(question)} </div>  
                        :   question.question_type === "TEXT" ?
                        <div> {this.textQuestion(question)} </div>  
                        : ""
                   
                    ))}
                    <div className="col-xs-12 col-sm-12 col-md-12" style={{ marginTop: "3%" }}>
                        {/* <div className="form1"> <h5>Download your prescription using <b>PurpleDocs</b> application:</h5></div>
                          
                        <div className="form1">
                            <a className="btn1 btn1-google" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.purplebits.android.mypurpledocs" target="_blank" title="Google Play">Google Play</a>
                        </div> */}
                        <div className=" col-xs-12 col-sm-12 col-md-12" >
                            <div className=" col-xs-12 col-sm-6 col-md-6" style={{textAlign:"center"}}> <button style={{  outline : "none" }} onClick={this.skipHandler}  type="button" className="button button5">
                              Later </button>
                            </div>
                          
                            <div className=" col-xs-12 col-sm-6 col-md-6" style={{textAlign:"center"}}>  <button style={{ outline : "none" }} onClick={this.submitHandler} disabled={this.state.isResponseEmpty} type="button" className="button button5">
                              Submit </button>
                            </div>

                        </div>
                        <div className=" col-xs-12 col-sm-12  col-md-12 form1"> 
            {  localStorage.getItem('isDoctorEndCall' ) !== '1' ?
             (<button className="button button5" onClick={this.callHandler} style={{textAlign: "center" , marginTop:"2%",fontFamily:"120%",outline:"none",border:"2px solid #ABB2B9", paddingTop: "0.5%", paddingBottom: "0.5%", fontStyle: "bold", backgroundColor:"white", color: "#1a73e8"}}>Rejoin</button>) 
             : "" }
                  </div>
                       
                    </div>

            </div>
              
              </Modal>
              </MobileView>
                ):
                (
                    <TabletView>
                    <Modal 
                    visible={this.state.visible}
                    width = "70%"
                    effect="fadeInUp"
                    onClickAway={this.skipHandler}
                    >
                    <div className="card2 " style={{ display:"block",width:"100%", paddingBottom: "2%", color: 'black'}} >
                        
                            <div className=" col-xs-12 col-sm-12 col-md-12" style={{ backgroundColor: "#d6e4e0d3" }}>
                                <div className=" col-xs-12 col-sm-6 col-md-6 ">
                                    <img src={Logo} style={{ width: "60%", height: "45%", marginTop: "5%", marginBottom: "2%" }}></img>
                                </div>
                                <div className=" col-xs-12 col-sm-6 col-md-6 ">
                                    <h3 style={{ fontFamily: "comic sans", fontStyle: "italic", color: "black", float: "right" }}>
                                        Your Feedback</h3>
                                </div>
                            </div>
        
                            
                            {this.state.questionList.map(question => (
        
                                question.question = question.question.replace("##DOCTORNAME##", localStorage.getItem('doctorName')),
                                question.question_type === "RADIO" ?
                             <div> {this.radioQuestion(question)} </div>  
                                :   question.question_type === "TEXT" ?
                                <div> {this.textQuestion(question)} </div>  
                                : ""
                           
                            ))}
                            <div className="col-xs-12 col-sm-12 col-md-12" style={{ marginTop: "3%" }}>
                                {/* <div className="form1"> <h5>Download your prescription using <b>PurpleDocs</b> application:</h5></div>
                                  
                                <div className="form1">
                                    <a className="btn1 btn1-google" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.purplebits.android.mypurpledocs" target="_blank" title="Google Play">Google Play</a>
                                </div> */}
                                <div className=" col-xs-12 col-sm-12 col-md-12" >
                                    <div className=" col-xs-12 col-sm-6 col-md-6" style={{textAlign:"center"}}> <button style={{  outline : "none" }} onClick={this.skipHandler}  type="button" className="button button5">
                                      Later </button>
                                    </div>
                                  
                                    <div className=" col-xs-12 col-sm-6 col-md-6" style={{textAlign:"center"}}>  <button style={{ outline : "none" }} onClick={this.submitHandler} disabled={this.state.isResponseEmpty} type="button" className="button button5">
                                      Submit </button>
                                    </div>
        
                                </div>
                                <div className=" col-xs-12 col-sm-12  col-md-12 form1"> 
                    {  localStorage.getItem('isDoctorEndCall' ) !== '1' ?
                     (<button className="button button5" onClick={this.callHandler} style={{textAlign: "center" , marginTop:"2%",fontFamily:"120%",outline:"none",border:"2px solid #ABB2B9", paddingTop: "0.5%", paddingBottom: "0.5%", fontStyle: "bold", backgroundColor:"white", color: "#1a73e8"}}>Rejoin</button>) 
                     : "" }
                          </div>
                               
                            </div>
        
                    </div>
                      
                      </Modal>
                      </TabletView>
                )}
        { !isMobile ? (
              <BrowserView>
              <Modal 
            visible={this.state.visible}
            width = "40%"
            effect="fadeInUp"
            onClickAway={this.skipHandler}
            >
            <div className="card3 " style={{ display:"block",width:"100%", paddingBottom: "2%", color: 'black',overflowY:"hidden"}} >
                
                    <div className=" col-xs-12 col-sm-12 col-md-12" style={{ backgroundColor: "#d6e4e0d3" }}>
                        <div className=" col-xs-12 col-sm-6 col-md-6 ">
                            <img src={Logo} style={{ width: "60%", height: "45%", marginTop: "5%", marginBottom: "2%" }}></img>
                        </div>
                        <div className=" col-xs-12 col-sm-6 col-md-6 ">
                            <h3 style={{ fontFamily: "comic sans", fontStyle: "italic", color: "black", float: "right" }}>
                                Your Feedback</h3>
                        </div>
                    </div>

                    
                    {this.state.questionList.map(question => (

                        question.question = question.question.replace("##DOCTORNAME##", localStorage.getItem('doctorName')),
                        question.question_type === "RADIO" ?
                     <div> {this.radioQuestion(question)} </div>  
                        :   question.question_type === "TEXT" ?
                        <div> {this.textQuestion(question)} </div>  
                        : ""
                   
                    ))}
                    <div className="col-xs-12 col-sm-12 col-md-12" style={{ marginTop: "3%" }}>
                        {/* <div className="form1"> <h5>Download your prescription using <b>PurpleDocs</b> application:</h5></div>
                          
                        <div className="form1">
                            <a className="btn1 btn1-google" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.purplebits.android.mypurpledocs" target="_blank" title="Google Play">Google Play</a>
                        </div> */}
                        <div className=" col-xs-12 col-sm-12 col-md-12" >
                            <div className=" col-xs-12 col-sm-6 col-md-6" style={{textAlign:"center"}}> <button style={{  outline : "none" }} onClick={this.skipHandler}  type="button" className="button button5">
                              Later </button>
                            </div>
                          
                            <div className=" col-xs-12 col-sm-6 col-md-6" style={{textAlign:"center"}}>  <button style={{ outline : "none" }} onClick={this.submitHandler} disabled={this.state.isResponseEmpty} type="button" className="button button5">
                              Submit </button>
                            </div>

                        </div>
                        <div className=" col-xs-12 col-sm-12  col-md-12 form1"> 
            {  localStorage.getItem('isDoctorEndCall' ) !== '1' ?
             (<button className="button button5" onClick={this.callHandler} style={{textAlign: "center" , marginTop:"2%",fontFamily:"120%",outline:"none",border:"2px solid #ABB2B9", paddingTop: "0.5%", paddingBottom: "0.5%", fontStyle: "bold", backgroundColor:"white", color: "#1a73e8"}}>Rejoin</button>) 
             : "" }
                  </div>
                       
                    </div>

            </div>
              
              </Modal>
              </BrowserView>
        ) : ""}
              </section>

        )
                                      
    }





}
export default FeedBack