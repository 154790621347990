import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import CallEnd from '@material-ui/icons/CallEnd';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';

import Strings from '../../../Strings.js';
import { Link } from "react-router-dom";
import {browserName,browserVersion,mobileModel,osName,osVersion,isWindows, isMobile , isBrowser} from 'react-device-detect';

const axios = require('axios');
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      margin: theme.spacing(1),
      width: '35px',
      height: '30px',
    },
  })
);


export default function EndCallButton() {
  const classes = useStyles();

  const disconnectCall = () => {


    var type: string | null = null;
    var conversationId: string | null = null;
    conversationId = localStorage.getItem('conversation_id');
    //URL.substring(URL.lastIndexOf("conv_") + 5, URL.lastIndexOf("conv_") + 5 + 10);
    var conv = "conv_" + conversationId;
    var name =  localStorage.getItem('name')+"";   
    name = name.replace(".", "");
    name = name.replace(/\s/g, '_');
    type = localStorage.getItem('type');
    window.history.replaceState(null, '', window.encodeURI(`/${type}/${conv}/${name}${window.location.search || ''}`));
    var deviceInfo = {
      browserName  :browserName,
      mobileModel  : mobileModel,
      browserVersion:browserVersion,
      osName: osName,
      osVersion: osVersion,
      isMobile:isMobile,
      isBrowser: isBrowser,
      isWindows: isWindows,
     }
  //  if (URL.indexOf("/p/") !== -1) {
      
  //  }
//else if (URL.indexOf("/d/") !== -1) {
 //     type = URL.substring(URL.lastIndexOf("/d/") + 1, URL.lastIndexOf("/d/") + 2);
//}

    axios.post(Strings.title.urls.baseUrl + '/Participants/Room/' + conversationId + '/removeParticipantWithDeviceInfo/' + name + '/' + type , {"deviceInfo" : deviceInfo})
      .then(() => {

        if (type === 'p') {
      //    type = URL.substring(URL.lastIndexOf("/p/") + 1, URL.lastIndexOf("/p/") + 2);
       
          axios.post(Strings.title.urls.baseUrl + '/Participants/connectedParticipantsWithDeviceInfo/' + conversationId,{"deviceInfo" : deviceInfo})
            .then(() => {
            }).catch((error: any) => {
            });

        } else if (type === 'd') {

       //   type = URL.substring(URL.lastIndexOf("/d/") + 1, URL.lastIndexOf("/d/") + 2);
          const config = { headers: { 'Content-Type': 'application/json' } };

          axios.put(Strings.title.urls.baseUrl + '/room/completeRoomWithDeviceInfo', { "conversationId": conversationId, "deviceInfo": deviceInfo }, config)
            .then().catch((error: any) => {
            });
        }
      
      })
      .catch((error: any) => {
      });
      window.history.replaceState(null, '', window.encodeURI(`/${type}/${conv}/${name}${window.location.search || ''}`));
  }
//component={FeedBack}
  return (

    <Tooltip title={'End Call'}  onClick={disconnectCall} placement="top" PopperProps={{ disablePortal: true }}>
      <Fab className={classes.fab}  color="primary">
        <Link to="/FeedBack" style={{color: "white"}} >
          <CallEnd />
        </Link>
      </Fab>
    </Tooltip>
  );
}
