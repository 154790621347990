const Strings = {
    title: {
      welcome: {
        permissionGranted: "Camera and microphone permission granted",
        permissionDenied: "Camera and microphone permission denied",
        permissionVideo: "Video permission denied",
        permissionMicrophone: "Microphone permission denied",
      },
      color: {
        colorGranted: "green",
        colorDenied: "#a61111"
  
      },
      icon: {
        ban: "fa fa-ban",
        granted: "fa fa-check-circle"
      },
      label: {
        buttonMsg: "Begin Video Consultation"
      },
      twilio: {
        roomCompleteByDoctor: "Video consultation is over",
        consultationType: "Doctor ended the call  ",
        roomCompletedMessage: "Thank You for choosing PurpleDocs",
        onVideoMsg: "Tele consultation between "
      },
      duplicateParticipant: {
        errorTitle: "Alert",
        contextText: "Duplicate Participant",
        message: "The patient session is already in progress, the Session will Restart!"
      },
      roomNotFound: {
        errorTitle: "Alert",
        contextText: "Room Not Found",
        message: "Please Rejoin the Link Again from Beginning!"
      },
      permissionDeniedBrowser: {
        browser_Chrom: "For Chrome browser",
        browser_Firefox: "For Firefox browser",
        browser_Safari: "For Safari browser",
        browser_Ie: "For Internet Explorer browser"
      },
      urls: {
        
      //  baseUrl: "http://localhost:8080/PurpleDocsService_VideoConferencing",
//baseUrlForLAN: "http://localhost:8080/PurpleDocsServices_LAN"
        // baseUrl: "https://www.mypurpledocs.com/PD_Video_TEST",
      //   baseUrlForLAN: "https://45.249.111.20:8085/PDServices_v1/",
          baseUrl: "https://www.mypurpledocs.com/PD_Video",
          baseUrlForLAN: "https://www.mypurpledocs.com/PurpleDocsServices_LAN"

      },
      browserPermissionHelpPages: {
        chrome: "https://support.google.com/chrome/answer/2693767?co=GENIE.Platform%3DDesktop&hl=en&oco=1",
        fireFox: "https://support.mozilla.org/en-US/kb/how-manage-your-camera-and-microphone-permissions",
        internetExplorer: "https://smartserve.zendesk.com/hc/en-us/articles/360012330533-How-do-I-check-my-browser-permissions-for-my-camera-and-microphone-",
        opera: "https://help.opera.com/en/latest/web-preferences/",
        safari: "https://help.doxy.me/en/articles/836274-allow-access-to-camera-in-safari-mobile-tablet"
  
      },
      fileSize : 5 * 1024 *1024
  
    }
  }
  export default Strings