import React, {useState} from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { LocalAudioTrack, LocalVideoTrack, Participant, RemoteAudioTrack, RemoteVideoTrack } from 'twilio-video';

import AudioLevelIndicator from '../AudioLevelIndicator/AudioLevelIndicator';
import BandwidthWarning from '../BandwidthWarning/BandwidthWarning';
import NetworkQualityLevel from '../NewtorkQualityLevel/NetworkQualityLevel';
import ParticipantConnectionIndicator from './ParticipantConnectionIndicator/ParticipantConnectionIndicator';
import PinIcon from './PinIcon/PinIcon';
import ScreenShare from '@material-ui/icons/ScreenShare';
import VideocamOff from '@material-ui/icons/VideocamOff';

import useIsTrackEnabled from '../../hooks/useIsTrackEnabled/useIsTrackEnabled';
import useParticipantNetworkQualityLevel from '../../hooks/useParticipantNetworkQualityLevel/useParticipantNetworkQualityLevel';
import usePublications from '../../hooks/usePublications/usePublications';
import useIsTrackSwitchedOff from '../../hooks/useIsTrackSwitchedOff/useIsTrackSwitchedOff';
import useTrack from '../../hooks/useTrack/useTrack';
import MenuBar from '../MenuBar/MenuBar';
import Menu from '../MenuBar/Menu/Menu';
import InfoIcon from '@material-ui/icons/Info';
import ReconnectingNotification from '../ReconnectingNotification/ReconnectingNotification';
import { Snackbar, SnackbarContent } from '@material-ui/core';
import { BrowserView, MobileView, TabletView ,isMobileOnly, isMobile } from 'react-device-detect';
import { red } from '@material-ui/core/colors';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      height: `${(theme.sidebarWidth * 9) / 16}px`,
      overflow: 'hidden',
      cursor: 'pointer',
      '& video': {
        filter: 'none',
      },
      '& svg': {
        stroke: 'black',
        strokeWidth: '0.8px',
      },
      [theme.breakpoints.down('xs')]: {
        height: theme.sidebarMobileHeight,
        width: `${(theme.sidebarMobileHeight * 16) / 9}px`,
        marginRight: '3px',
        fontSize: '10px',
      },
    },
    isVideoSwitchedOff: {
      '& video': {
        filter: 'blur(4px) grayscale(1) brightness(0.5)',
      },
    },
    infoContainer: {
      position: 'absolute',
      zIndex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
      padding: '0.4em',
      width: '100%',
      background: 'transparent',
    },
    hideVideo: {
      background: 'black',
    },
    identity: {
      background: 'rgba(0, 0, 0, 0.7)',
      padding: '0.1em 0.3em',
      margin: 0,
      display: 'flex',
      alignItems: 'center',
      fontSize:"10px",
    },
    infoRow: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    snackbar: {
      backgroundColor: '#303030',
      marginTop : "-10%",
      border:"none",
      boxShadow: "none",
      outline:"none",
      width : "1000%"

    },
    snackbarMob: {
      backgroundColor: '#303030',
      border:"none",
      boxShadow: "none",
      marginTop : "-2%",
      outline:"none",
      width : "1000%"

    },
    snackbarTab: {
      backgroundColor: '#303030',
      border:"none",
      marginTop : "-9%",
      boxShadow: "none",
      outline:"none",
      width : "1000%"

    },
    message: {
      display: 'flex',
      alignItems: 'center',
      fontSize : '150%',
      color : "white"
    },
    messageMob: {
      display: 'flex',
      alignItems: 'center',
      fontSize : '130%',
      color : "white"
    },
    messageTab: {
      display: 'flex',
      alignItems: 'center',
      color : "white",
      fontSize : '200%',
    },
    
    icon: {
      marginRight: '0.8em',
    },
  })
);

interface ParticipantInfoProps {
  participant: Participant;
  children: React.ReactNode;
  onClick: () => void;
  isSelected: boolean;
}
var message  : String = '';
var temp = true;
var videoStatus ="";
var audioStatus = "";
export default function ParticipantInfo({ participant, onClick, isSelected, children }: ParticipantInfoProps) {
  const publications = usePublications(participant);

  const audioPublication = publications.find(p => p.kind === 'audio');
  const videoPublication = publications.find(p => p.trackName.includes('camera'));

  const networkQualityLevel = useParticipantNetworkQualityLevel(participant);
  const isVideoEnabled = Boolean(videoPublication);
  const isScreenShareEnabled = publications.find(p => p.trackName.includes('screen'));

  const videoTrack = useTrack(videoPublication);
  const isVideoSwitchedOff = useIsTrackSwitchedOff(videoTrack as LocalVideoTrack | RemoteVideoTrack);

  const audioTrack = useTrack(audioPublication) as LocalAudioTrack | RemoteAudioTrack;
  const isTrackEnabled = useIsTrackEnabled(audioTrack as LocalAudioTrack | RemoteAudioTrack);
  const isRemoteEnabled = useIsTrackEnabled(audioTrack as RemoteAudioTrack)
  const classes = useStyles();

 
  temp = true;
  if(isVideoEnabled && isTrackEnabled){
  message = participant.identity.replace(/_/g,'. ');
  videoStatus = " ON";
  audioStatus = " ON";
  }
 else if(isVideoEnabled && !isTrackEnabled){
 message = participant.identity.replace(/_/g,'. ');
 videoStatus =  " ON";
 audioStatus =" OFF";
 }
 else if(!isVideoEnabled && isTrackEnabled){
 message = participant.identity.replace(/_/g,'. ');
 videoStatus = " OFF";
 audioStatus = " ON";
 }
 else if(!isVideoEnabled && !isTrackEnabled){
 message = participant.identity.replace(/_/g,'. ');
 videoStatus = " OFF";
 audioStatus =" OFF";
 }
 if(message === null)
 message ="";

  return (
   
    <div
      className={clsx(classes.container, {
        [classes.isVideoSwitchedOff]: isVideoSwitchedOff,
      })}
      onClick={onClick}
      data-cy-participant={participant.identity}
    >
      {!isMobile ? (
       <BrowserView>
      <Snackbar id="snackbar" anchorOrigin={{ vertical: 'top', horizontal: 'left' }}  open={temp} >
      <SnackbarContent
        className={classes.snackbar}
        message={
          <span className={classes.message}>
            <InfoIcon className={classes.icon} />
            <span>{message} |  Video :  </span> { isVideoEnabled ? (<span style={{color: "#43A047" , paddingLeft : "5px" ,fontStyle : "bold"}}>{videoStatus}</span>) : (<span style={{color: "#F44336", paddingLeft : "5px" ,fontStyle : "bold"}}>{videoStatus}</span>)} <span >, Audio :  </span> { isTrackEnabled ? (<span style={{color: "#43A047", paddingLeft : "5px" ,fontStyle : "bold"}}>{audioStatus}</span>) : (<span style={{color: "#F44336", paddingLeft : "5px" ,fontStyle : "bold"}}>{audioStatus}</span>)}
          </span>
        }
      />
          </Snackbar>
     

      </BrowserView>
      ) : ''}
{isMobileOnly  ? (
      <MobileView>
      <Snackbar id="snackbarMob" anchorOrigin={{ vertical: 'top', horizontal: 'left' }}  open={temp} >
      <SnackbarContent
        className={classes.snackbarMob}
        message={
          <span className={classes.messageMob}>
            <InfoIcon className={classes.icon} />
            <span>{message} |  Video :  </span> { isVideoEnabled ? (<span style={{color: "#43A047" , paddingLeft : "5px" ,fontStyle : "bold"}}>{videoStatus}</span>) : (<span style={{color: "#F44336", paddingLeft : "5px" ,fontStyle : "bold"}}>{videoStatus}</span>)} <span >, Audio :  </span> { isTrackEnabled ? (<span style={{color: "#43A047", paddingLeft : "5px" ,fontStyle : "bold"}}>{audioStatus}</span>) : (<span style={{color: "#F44336", paddingLeft : "5px" ,fontStyle : "bold"}}>{audioStatus}</span>)}
          </span>
        }
      />
          </Snackbar>
      </MobileView>
) : (
      <TabletView>
      <Snackbar id="snackbar" anchorOrigin={{ vertical: 'top', horizontal: 'left' }}  open={temp} >
      <SnackbarContent
        className={classes.snackbarTab}
        message={
          <span className={classes.messageTab}>
            <InfoIcon className={classes.icon} />
            <span>{message} |  Video :  </span> { isVideoEnabled ? (<span style={{color: "#43A047" , paddingLeft : "5px" ,fontStyle : "bold"}}>{videoStatus}</span>) : (<span style={{color: "#F44336", paddingLeft : "5px" ,fontStyle : "bold"}}>{videoStatus}</span>)} <span >, Audio :  </span> { isTrackEnabled ? (<span style={{color: "#43A047", paddingLeft : "5px" ,fontStyle : "bold"}}>{audioStatus}</span>) : (<span style={{color: "#F44336", paddingLeft : "5px" ,fontStyle : "bold"}}>{audioStatus}</span>)}
         </span>
        }
      />
      
      </Snackbar>
      </TabletView>
)}
     

      <div className={clsx(classes.infoContainer, { [classes.hideVideo]: !isVideoEnabled })}>
        <div className={classes.infoRow}>
          <h4 className={classes.identity}>
            <ParticipantConnectionIndicator participant={participant} />
            {participant.identity.replace(/_/g,' ') !== null ? participant.identity.replace(/_/g,' ') : ""}
          </h4>
          <NetworkQualityLevel qualityLevel={networkQualityLevel} />
        </div>
        <div>
          <AudioLevelIndicator audioTrack={audioTrack} background="white" />
          {!isVideoEnabled && <VideocamOff />}
          {isScreenShareEnabled && <ScreenShare />}
          {isSelected && <PinIcon />}
        </div>
      </div>
      {isVideoSwitchedOff && <BandwidthWarning />}
      {children}
    </div>
  );
}
