import React, { Component } from 'react';
import FeedBack from './FeedBack';
import Strings from '../../Strings';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
var code = 53118;
export default class PopupModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible : true,
            callDisconnected : true
        }
        console.log("Inside dialog");
    }
  
        
  
    openModal() {
        this.setState({
            visible : true
        });
    }

    closeModal() {
        console.log("closeModal");
        this.setState({
            visible : false
        });
    }

    dismissError = () =>{
        this.setState({
            callDisconnected : false
        })
    }

    render() {
        console.log("Inside render");
        if(this.state.callDisconnected){
            
            return(
                <Dialog  open={true} fullWidth={true} maxWidth="xs">
                <DialogTitle>{Strings.title.twilio.consultationType}</DialogTitle>
                <DialogContent>
                  <DialogContentText>{Strings.title.twilio.roomCompleteByDoctor}</DialogContentText>
                  { code && (
                    <pre>
                      <code>{Strings.title.twilio.roomCompletedMessage}</code>
                    </pre>
                  )}
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.dismissError} color="primary" autoFocus>
                    OK
                  </Button>
                </DialogActions>
              </Dialog>
            );
                 }else{
        return (
               <FeedBack/>  
        );
                 }
    }
}