import React from 'react';
import { styled } from '@material-ui/core/styles';
import Strings from '../../Strings';
import '../../css/main.css';
import UnsupportedBrowserWarning from '../UnsupportedBrowserWarning/UnsupportedBrowserWarning';
import { VideoProvider } from '../VideoProvider';
import ErrorDialog from '../ErrorDialog/ErrorDialog';
import App from '../../App';
import { useAppState } from '../../state';
import useConnectionOptions from '../../utils/useConnectionOptions/useConnectionOptions';
import CheckPermission from './CheckPermission';
import { BrowserView, MobileView } from 'react-device-detect';
import {browserName,browserVersion,mobileModel,osName,osVersion,isWindows, isMobile , isBrowser} from 'react-device-detect';
import { useAudioInputDevices, useVideoInputDevices } from '../MenuBar/DeviceSelector/deviceHooks/deviceHooks';
const axios = require('axios');
var os = require('os');


const Container = styled('div')({
  display: 'grid',
  gridTemplateRows: 'auto 1fr',
});

const Main = styled('main')({
  overflow: 'hidden',
});

const VideoApp = () => {
  const { error, setError } = useAppState();
  const connectionOptions = useConnectionOptions();

  return (
      <UnsupportedBrowserWarning>
          <VideoProvider options={connectionOptions} onError={setError}>
              <ErrorDialog dismissError={() => setError(null)} error={error} />
              <App />
          </VideoProvider>
      </UnsupportedBrowserWarning>
  );
};



class VideoSupportPage extends React.Component {
  constructor() {
    super();
    this.state = {
      changePage: false,
      stepsPage: false,
      permission: "",
      permissionMsg: "",
      icon: "",
      checked: 0,
      audio: 0,
      video: 0,
      person: "",
      instruction: false,
      isCorrectConversationId: false,
      type: null
    }
   
var interfaces = os.networkInterfaces();
var addresses = [];
for (var k in interfaces) {
    for (var k2 in interfaces[k]) {
        var address = interfaces[k][k2];
        if (address.family === 'IPv4' && !address.internal) {
          console.log("addresses : "+address);
            addresses.push(address.address);
        }
    }
}

console.log("addresses : "+addresses);

  }

  /* checkPermission = () => {
    if(audioInputDevices.length > 1) {
      this.setState({
        audio: 1
      });
      this.changeUI();
    } else {
      this.setState({
        audio: 0
      });
      this.changeUI();
    }

    if(videoInputDevices.length > 1) {
      this.setState({
        video: 1
      });
      this.changeUI();
    } else {
      this.setState({
        video: 0
      });
      this.changeUI();
    }
    window.navigator.mediaDevices.getUserMedia({
      video: true
    })
      .then(() => {

        this.setState({
          video: 1
        });
        this.changeUI();

      }).catch(() => {

        this.setState({
          video: 0
        });
        this.changeUI();
      }
      )

    window.navigator.mediaDevices.getUserMedia({
      audio: true
    })
      .then(() => {

        this.setState({
          audio: 1
        });
        this.changeUI();

      }).catch(() => {


        this.setState({
          audio: 0
        });
        this.changeUI();

      }
      ) 
  }*/

  changeUI = () => {
    this.setState({
      checked: 1
    });
    if (this.state.audio === 1 && this.state.video === 1) {

      this.setState({
        permission: Strings.title.color.colorGranted,
        permissionMsg: Strings.title.welcome.permissionGranted,
        icon: Strings.title.icon.granted,
        checked: 1,
        instruction: false
      });
    }
    else if (this.state.audio === 0 && this.state.video === 0) {

      this.setState({
        permission: Strings.title.color.colorDenied,
        permissionMsg: Strings.title.welcome.permissionDenied,
        icon: Strings.title.icon.ban,
        instruction: true

      });
    }
    else if (this.state.audio === 0) {

      this.setState({
        permission: Strings.title.color.colorDenied,
        permissionMsg: Strings.title.welcome.permissionVideo,
        icon: Strings.title.icon.ban,
        instruction: true
      });
    } else if (this.state.video === 0) {
      this.setState({
        permission: Strings.title.color.colorDenied,
        permissionMsg: Strings.title.welcome.permissionMicrophone,
        icon: Strings.title.icon.ban,
        instruction: true
      });

    }


  }
  redirect = () => {

    this.setState({
      changePage: true
    });
    var URL = window.location.href;
    var conversationId = null;

    if (URL.indexOf("/p/") !== -1) {
      this.setState({
        type: URL.substring(URL.lastIndexOf("/p/") + 1, URL.lastIndexOf("/p/") + 2)
      })
    } else if (URL.indexOf("/d/") !== -1) {
      this.setState({
        type: URL.substring(URL.lastIndexOf("/d/") + 1, URL.lastIndexOf("/d/") + 2)
      })
    }
    conversationId = URL.substring(URL.lastIndexOf("conv_") + 5, URL.lastIndexOf("conv_") + 15);

    var conv = "conv_" + conversationId;
    console.log("VideoSupportPage : "+Strings.title.urls.baseUrl + '/videoConsultation/getVideoConsultation/' + conversationId);
    axios.get(Strings.title.urls.baseUrl + '/videoConsultation/getVideoConsultation/' + conversationId)
      .then((response) => {

        if (response.data.result !== null) {
          var name;
          if (this.state.type === 'p')
            name = response.data.result.video_consultation.name;
          else if (this.state.type === 'd')
            name = response.data.result.doctor_details.doctor_name;
          window.history.replaceState(null, '', window.encodeURI(`/${this.state.type}/${conv}/${name}${window.location.search || ''}`));

          this.setState({
            isCorrectConversationId: true
          })
          console.log("VideoSupportPage.js =====> "+this.state.isCorrectConversationId);
        }
      })
      .catch((error) => {
        console.log("VideoSupportPage.js =====> "+error);
      });
  }

  redirectToSteps = () => {
    this.setState({
      stepsPage: true
    });
  }
  

     
  render() {
    
    if (this.state.changePage) {
      if (this.state.isCorrectConversationId) {
        return (
          < CheckPermission />
        );
      } else {
        return(
          <div style={{paddingRight: "10%" , paddingTop:"5%", paddingLeft:"10%" }}>
           <h1 style={{ color: "white", fontSize: "350%", fontFamily: "Serif", margin: "1%" }}>Your video consultation link seems to be broken, please ask your doctor to share the link via WhatsApp.</h1>
         </div>
          );
      }
    }else{
      {this.redirect()}
      return (
       <div></div>
      );
    }
    // if (this.state.stepsPage) {
    //   return (
    //     <div className="card" style={{ position: "fixed", color: 'black', textAlign: "center" }}>
    //       <h2>## You can look in the below links according to your preferrd browser ## </h2>
    //       <div style={{ padding: "2%" }}></div>
    //       <div className=" content col-xs-12 col-sm-12 col-md-12 " >
    //         <div className="col-xs-0 col-sm-2 col-md-3"></div>
    //         <div className="col-xs-12 col-sm-8 col-md-6" >
    //           <h3> <a href={Strings.title.browserPermissionHelpPages.chrome}>For Chrome Browser</a></h3>
    //         </div>
    //         <div className="col-xs-0 col-sm-2 col-md-3"></div>
    //       </div>
    //       <div style={{ padding: "1%" }}></div>
    //       <div className=" content col-xs-12 col-sm-12 col-md-12 " >
    //         <div className="col-xs-0 col-sm-2 col-md-3"></div>
    //         <div className="col-xs-12 col-sm-8 col-md-6" >
    //           <h3> <a href={Strings.title.browserPermissionHelpPages.fireFox}>For Fire-Fox Browser</a></h3>
    //         </div>
    //         <div className="col-xs-0 col-sm-2 col-md-3"></div>
    //       </div>
    //       <div style={{ padding: "1%" }}></div>
    //       <div className=" content col-xs-12 col-sm-12 col-md-12 " >
    //         <div className="col-xs-0 col-sm-2 col-md-3"></div>
    //         <div className="col-xs-12 col-sm-8 col-md-6" >
    //           <h3> <a href={Strings.title.browserPermissionHelpPages.internetExplorer}>For Internet-explorer Browser</a></h3>
    //         </div>
    //         <div className="col-xs-0 col-sm-2 col-md-3"></div>
    //       </div>
    //       <div style={{ padding: "1%" }}></div>
    //       <div className=" content col-xs-12 col-sm-12 col-md-12 " >
    //         <div className="col-xs-0 col-sm-2 col-md-3"></div>
    //         <div className="col-xs-12 col-sm-8 col-md-6" >
    //           <h3> <a href={Strings.title.browserPermissionHelpPages.safari}>For Safari Browser</a></h3>
    //         </div>
    //         <div className="col-xs-0 col-sm-2 col-md-3"></div>
    //       </div>
    //       <div style={{ padding: "1%" }}></div>
    //       <div className=" content col-xs-12 col-sm-12 col-md-12 " >
    //         <div className="col-xs-0 col-sm-2 col-md-3"></div>
    //         <div className="col-xs-12 col-sm-8 col-md-6" >
    //           <h3> <a href={Strings.title.browserPermissionHelpPages.opera}>For Opera Browser</a></h3>
    //         </div>
    //         <div className="col-xs-0 col-sm-2 col-md-3"></div>
    //       </div>

    //     </div>
    //   );
    // }

    /* const isChecked = this.state.checked;
    if (isChecked === 0)
      this.checkPermission(); */
   

  }
}

export default VideoSupportPage;
    // if (this.state.instruction) {
    //   return (

    //     <Container >
    //       {this.componentDidMount}
    //       <Main >
    //         <div className="card"  >

    //           <div style={{ textAlign: "center", fontSize: "200%" }} >
    //             <CheckPermission />
    //             {/* <div className="col-xs-12 col-sm-12 col-md-12" style={{ color: "#a61111", marginTop: "5%", fontSize: "140%" }}>
    //               <strong><i className={icon}></i>&nbsp;&nbsp;{this.state.permissionMsg}</strong>
    //             </div> */}
    //             <br /><br />
    //             <div style={{ color: "black", paddingTop: "6%" }}>

    //               To allow access to Camera/Microphone permission please click on this button :
    //           </div>
    //             <div style={{ padding: "3%" }}>
    //               <button type="button" className="btn btn-success" onMouseDown={this.redirectToSteps}>
    //                 Instructions</button>
    //             </div>
    //             <div className="col-md-12 col-sm-12 col-xs-12" style={{ padding: "1%", textAlign: "center" }}>
    //               <div className="input-effect">
    //                 If you are facing issues in the browser please download <strong>myPD app</strong> to talk
    //             to your doctor.<br />And use code : <span><input type="text" name="code" style={{ color: "red", textAlign: "center" }} value={localStorage.getItem('passCode')} readOnly /> </span>
    //               </div>
    //             </div>

    //             <div className="col-md-12 col-sm-12 col-xs-12" >
    //               <div >
    //                 <a rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.purplebits.android.mypurpledocs" target="_blank" style={{ color: 'white' }}>
    //                   <button type="button" className="btn btn-primary">
    //                     Download</button></a>
    //               </div>
    //             </div>
    //           </div>

    //           <br />

    //         </div>
    //       </Main>
    //     </Container>
    //   );

    // }
    // else {
      // return (

      //   <Container>
      //     {this.componentDidMount}
      //     <Main >
      //     <div  >
            {/* {!isMobile ? (
            <BrowserView>
            <div className="card_for_permission" style={{position:"fixed"}}>
              <div style={{ textAlign: "center", fontSize: "200%" }} >
                <div className='row'>
                <CheckPermission />
                {/* <div className="col-xs-12 col-sm-12 col-md-12" style={{ color: color, marginTop: "5%", fontSize: "170%" }}>
                  <strong><i className={icon}></i>&nbsp;&nbsp;{this.state.permissionMsg}</strong>

</div> 
</div>
                <div style={{ padding: "4%" }}></div>
                
                <div className="row input-effect" style={{color:"black"}} >
                  You are ready to begin the video-consultation in the browser.To continue please click here :
                </div>

                <div className="col-md-12 col-sm-12 col-xs-12" style={{ padding: "1%", textAlign: "center" }}>
                  <div className="input-effect">
                    <button type="button" className="btn btn-success" onMouseDown={this.redirect} >Continue in Browser</button>
                  </div>
                </div>

                <div className="col-md-12 col-sm-12 col-xs-12" style={{ padding: "1%", textAlign: "center" }}>
                  <div className="input-effect">
                    If you are facing issues in the browser please download <strong>myPD app</strong> to talk
                to your doctor.<br />And use code : <span><input type="text" name="code" style={{ color: "red", textAlign: "center" }} value={localStorage.getItem('passCode')} readOnly /> </span>
                  </div>
                </div>
               <div className="col-md-12 col-sm-12 col-xs-12" style={{ textAlign: "center" }} >
                  <div >
                    <h4 className="input-effect">Download the App:</h4>
                    <a rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.purplebits.android.mypurpledocs" target="_blank" style={{ color: 'white' }}>
                      <button type="button" className="btn btn-primary">
                        Download</button></a>
                  </div>
                </div> 

                <br />
              </div>
              </div>
              </BrowserView>
            ) : (
              <MobileView> */}
                {/* <div className="card" style={{ position: "fixed" }}>
              <div style={{ textAlign: "center", fontSize: "200%" }} >
                */}
            //     <CheckPermission />
                {/* <div className="col-xs-12 col-sm-12 col-md-12" style={{ color: color, marginTop: "5%", fontSize: "170%" }}>
                  <strong><i className={icon}></i>&nbsp;&nbsp;{this.state.permissionMsg}</strong>
                </div> */}
                {/* <div style={{ padding: "5%" }}></div>
                
                <div className="text1">
                  <h4 >You are ready to begin the video-consultation in the browser.To continue please click here :
                  </h4> </div> */}

                {/* <div className="col-md-12 col-sm-12 col-xs-12" style={{ padding: "1%", textAlign: "center" }}>
                  <div className="input-effect ">
                    <button type="button" className="btn btn-success" onMouseDown={this.redirect} >Continue in Browser</button>
                  </div>
                </div> */}

                {/* <div className="col-md-12 col-sm-12 col-xs-12" style={{ padding: "1%", textAlign: "center" }}>
                  <div className="input-effect">
                    If you are facing issues in the browser please download <strong>myPD app</strong> to talk
                to your doctor.<br />And use code : <span><input type="text" name="code" style={{ color: "red", textAlign: "center" }} value={localStorage.getItem('passCode')} readOnly /> </span>
                  </div>
                </div> */}
               {/* <div className="col-md-12 col-sm-12 col-xs-12" style={{ textAlign: "center" }} >
                  <div >
                    <h4 style={{color:"black",fontFamily:"Serif"}}>Download the app:</h4>
                    <a rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.purplebits.android.mypurpledocs" target="_blank" style={{ color: 'white' }}>
                      <button type="button" className="btn btn-primary">
                        Download</button></a>
                  </div>
                </div>  */}
{/* 
                <br />
              </div>
            </div>
              </MobileView>
            )}*/}
      //     </div> 
      //     </Main>
      //   </Container>
      // )


    

//   }
// }

// export default VideoSupportPage;