import { LocalVideoTrack } from 'twilio-video';
import { useCallback, useRef, useState } from 'react';
import useVideoContext from '../useVideoContext/useVideoContext';
import {browserName,browserVersion,mobileModel,osName,osVersion,isWindows, isMobile , isBrowser} from 'react-device-detect';
import Strings from '../../Strings';

export default function useLocalVideoToggle() {
  const {
    room: { localParticipant },
    localTracks,
    getLocalVideoTrack,
    removeLocalVideoTrack,
    onError,
  } = useVideoContext();
  const videoTrack = localTracks.find(track => track.name.includes('camera')) as LocalVideoTrack;
  const [isPublishing, setIspublishing] = useState(false);
  const previousDeviceIdRef = useRef<string>();
  const callAPI = (eventName : any , error : any) =>{
    const axios = require('axios');
    var pId = "" , id  ;
    var type= localStorage.getItem('type');
    type === 'p' ?  pId = localStorage.getItem('patientId')+"" : pId = localStorage.getItem('doctorId')+"";

   var deviceInfo = {
     "browserName " :browserName,
     "mobileModel " : mobileModel,
     "browserVersion":browserVersion,
     "osName": osName,
     "osVersion": osVersion,
     "isMobile":isMobile,
     "isBrowser": isBrowser,
     "isWindows": isWindows,
    }
    var description
    if(error === null){
       description ={
      "identity" : localStorage.getItem('name')
    }}else{
      description ={
        "identity" : localStorage.getItem('name'),
        "error" : error
      }
    }
    
    var data = {
      conversation_id: localStorage.getItem('conversation_id')+"",
      description: description,
      deviceInfo: deviceInfo,
      event: eventName,
      participant_id: pId,
      participant_type: type
  };
    axios.post(Strings.title.urls.baseUrl+'/logs/setParticipantLog',data).then((response : any)=>{
    console.log(response.data);
  })
  } 
// Testing code start
  // function defaultDisableVideo() {
  //   previousDeviceIdRef.current = videoTrack.mediaStreamTrack.getSettings().deviceId;
  //   const localTrackPublication = localParticipant?.unpublishTrack(videoTrack);
  //   // TODO: remove when SDK implements this event. See: https://issues.corp.twilio.com/browse/JSDK-2592
  //   localParticipant?.emit('trackUnpublished', localTrackPublication);
  //   callAPI('video-track-removed', null);
  //   removeLocalVideoTrack();
  // }
  // defaultDisableVideo();
// Testing code end
  const toggleVideoEnabled = useCallback(() => {
    if (!isPublishing) {
      if (videoTrack) {
        previousDeviceIdRef.current = videoTrack.mediaStreamTrack.getSettings().deviceId;
        const localTrackPublication = localParticipant?.unpublishTrack(videoTrack);
        // TODO: remove when SDK implements this event. See: https://issues.corp.twilio.com/browse/JSDK-2592
        localParticipant?.emit('trackUnpublished', localTrackPublication);
        callAPI('video-track-removed' ,null);
        removeLocalVideoTrack();
      } else {
        setIspublishing(true);
        callAPI('video-track-added', null);
        getLocalVideoTrack({ deviceId: { exact: previousDeviceIdRef.current } })
          .then((track: LocalVideoTrack) => localParticipant?.publishTrack(track, { priority: 'low' }))
          .catch((onError)=>{
            callAPI('error-publishing-video-track' , onError);
          })
          .finally(() => {setIspublishing(false)
              
          });
      }
    }
  }, [videoTrack, localParticipant, getLocalVideoTrack, isPublishing, onError, removeLocalVideoTrack]);

  return [!!videoTrack, toggleVideoEnabled] as const;
}
